/* HERO */
.hero-border-left-bottom-inner {
  position: absolute;
  bottom: -6rem;
  left: -6rem;
  height: 24rem;
  width: 24rem;
  border-radius: 50%;
  border-width: 6rem;
  border-style: solid;
  border-color: rgb(249 250 251);
  border-image: initial;
  z-index: 10;
}

.hero-border-left-bottom {
  position: absolute;
  bottom: 0rem;
  left: 0px;
  height: 6rem;
  width: 6rem;
  overflow: hidden;
}

.hero-border-right-bottom {
  position: absolute;
  bottom: -6rem;
  right: 0px;
  height: 6rem;
  width: 6rem;
  overflow: hidden;
  transform: rotate(270deg);
}

.hero-border-right-bottom-inner {
  position: absolute;
  bottom: -6.1rem;
  right: -6rem;
  height: 24rem;
  width: 24rem;
  border-radius: 50%;
  border-width: 6rem;

  border-style: solid;
  border-color: rgb(88 28 135);
  border-image: initial;
  z-index: 10;
}

.rsvp-text {
  position: absolute;
  z-index: 1;
  top: 40px;
  left: 0;
  right: 0;
  line-height: 20px;
  text-align: center;
  font-weight: bold;
}

.help-page code {
  border: 1px solid lightblue;
  background: #add8e636;
  display: inline-block;
  padding: 0 2rem;
  margin-top: 2rem;
}
.help-page code > ul {
  flex-direction: column;
}
.help-page .intro-text {
  width: 350px;
  margin: auto;
}
.help-page .card {
  position: relative;
  display: block;
  border-radius: 10px;
  margin: 3rem auto 5em;
  padding: 4em;
  width: 90%;
  max-width: 50rem;
  background: white;
  box-shadow: 0 4px 1.5em 0 rgba(27, 27, 27, 0.1);
}
.help-page p {
  font-weight: 300;
}
.help-page ol {
  padding-left: 20px;
}
.help-page ol > li {
  padding: 1rem;
}
.help-page ul {
  list-style: none;
  display: flex;
  padding: 2rem 0;
  justify-content: space-between;
}
.help-page a {
  text-decoration: none;
}
.help-page .browser img {
  width: 80px;
  height: 80px;
  margin-bottom: 2rem;
}

.help-page span {
  font-weight: 100;
}
.help-page .bold {
  padding-bottom: 7px;

  font-weight: 700;
}

.help-page .install_link {
  text-decoration: underline;
  color: #2877ed;
  display: block;
  padding: 0.5rem 0;
}
.help-page .browser {
  height: 220px;
  width: 210px;
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.help-page .browser:hover {
  box-shadow: 0 4px 1.5em 0 rgba(27, 27, 27, 0.1);
}

.help-page .team-viewer-logo {
  height: 38px !important;
  width: auto !important;
}

.help-page .click-run {
  display: inline-block;
  position: relative;
}
.help-page .click-run::after {
  position: absolute;
  content: "";
  width: 100px;
  height: 40px;
  border-radius: 10px;
  border: 5px solid darkgreen;
  top: 50%;
  left: 50%;
}

.help-page h1 {
  margin-bottom: 2em;
}

.help-page h1,
p {
  margin: 0 auto;
}

.help-page h1 {
  font-size: 2.8em;
  font-weight: 700;
  margin: 0 0 0.5em 0;
}

.help-page p {
  margin: 1em auto;
}
